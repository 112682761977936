import { Fragment, useEffect, useState } from 'react';
import { getCurrentUserId } from 'scripts/auth/session';
import UserService from 'scripts/services/UserService';
import { OnboardingFormLabel } from 'src/components/oldOnboarding/common/onboardingScreen/OnboardingScreen';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import TextInput from 'src/design/components/textInput/TextInput';
import Spacing from 'src/design/styleguide/spacing/Spacing';

const INITTIME = 30; // 30s

const SmsVerify = ({ onChangeCode }) => {
  const [timeLeft, setTimeLeft] = useState(INITTIME);

  const verify = () => {
    const userid = getCurrentUserId();
    if (userid) UserService.getSmsOtp(userid);
  };

  const runCounter = () => {
    if (timeLeft === INITTIME) verify();
    if (timeLeft > 0) {
      const timeout = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  };

  const resetCounter = () => {
    setTimeLeft(INITTIME);
  };

  useEffect(() => {
    runCounter();
  }, [timeLeft]);

  return (
    <Fragment>
      <OnboardingFormLabel>
        Enter the SMS Code{' '}
        {timeLeft ? (
          ` (Resend code in ${timeLeft} S)`
        ) : (
          <Styledlink onClick={resetCounter}>Resend Code</Styledlink>
        )}
      </OnboardingFormLabel>
      <Spacing bottom="normal">
        <TextInput onChange={onChangeCode} />
      </Spacing>
    </Fragment>
  );
};

export default SmsVerify;
