import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {
  isAPreOrderOrComingSoonProperty,
  calculateGearingEffect,
  getSortedGrowthMetricsPairs,
} from 'scripts/utilities/propertyHelper';
import {
  percentDecimal,
  percentDecimalWithMinusSign,
  percent,
} from 'scripts/utilities/formatters';
import {
  property as propertyPropType,
  propertyAverageAnnualGrowthMetrics as propertyAverageAnnualGrowthMetricsPropType,
} from 'scripts/constants/PropTypes';
import { whatIsGearingEffectArticleUrl } from 'src/utils/pageUrls';
import FinancialsPanel, {
  RowValue,
  RowContainer,
  SectionTitle,
  RowTitleWithSelector,
  RowTitle,
} from 'scripts/components/shared/FinancialsPanel';
import Spacing from 'src/design/styleguide/spacing/Spacing';
import Styledlink from 'src/design/components/hyperlink/Styledlink';
import {
  getGearingEffectPopoverText,
  historicalGrowthTitle,
  historicalSuburbGrowthPopoverText,
  strToTitle,
} from 'src/settings/text';
import { ForecastCapitalGrowth } from 'src/settings/financials';
import Formatters from 'scripts/utilities/formattersV2';

const GearingRowContainer = styled(RowContainer)`
  padding-bottom: 0;
`;

class InvestmentYields extends Component {
  static propTypes = {
    property: propertyPropType.isRequired,
    propertyAverageAnnualGrowthMetrics:
      propertyAverageAnnualGrowthMetricsPropType,
  };

  state = {
    numberOfYearsSelected:
      this.props.propertyAverageAnnualGrowthMetrics &&
      getSortedGrowthMetricsPairs(
        this.props.propertyAverageAnnualGrowthMetrics
      ).reverse()[0][0],
  };

  render() {
    const { property, propertyAverageAnnualGrowthMetrics } = this.props;

    const { numberOfYearsSelected } = this.state;

    const yearsOptions = getSortedGrowthMetricsPairs(
      this.props.propertyAverageAnnualGrowthMetrics
    )
      .reverse()
      .map(([year]) => year);

    const brickPriceAdjective = isAPreOrderOrComingSoonProperty(property)
      ? 'Initial'
      : 'current Lowest Available';
    const netRentalIncomePopoverText =
      property.propertyType === 'development'
        ? 'Estimated net rental yield for a development fund is 0% during the development phase. All fund and holding costs incurred during the development phase are accounted for in the objective capital growth rate.'
        : property.tenantStatus.tenanted
        ? `The annualised Net Rental Yield, based on the ${brickPriceAdjective} Brick Price. See full calculation on Monthly Distributions tab.`
        : `The forecasted Net Rental Yield, based on the ${brickPriceAdjective} Brick Price and weekly rent expected by the BrickX property management team. See full calculations on the Monthly Distributions tab.`;
    const developmentObjectiveCapitalGrowthRatePopoverText =
      'Objective capital growth rate is calculated as the estimated value of the developed property, divided by all costs incurred by the fund during the development period.';

    const numberOfYearsInteger = parseInt(numberOfYearsSelected, 10);
    const yearsOrYear = numberOfYearsInteger > 1 ? 'years' : 'year';
    const historicalSuburbGrowthPopover = historicalSuburbGrowthPopoverText(
      property.propertyType,
      numberOfYearsInteger,
      yearsOrYear
    );
    const gearingEffectPopoverText = getGearingEffectPopoverText(
      property.propertyType
    );
    const historicalGrowthOverSelectedYears = _.get(
      propertyAverageAnnualGrowthMetrics,
      [numberOfYearsSelected]
    );
    const averageAnnualGrowthPercent =
      typeof historicalGrowthOverSelectedYears === 'number'
        ? historicalGrowthOverSelectedYears::percentDecimalWithMinusSign()
        : '---';

    const gearingEffect = calculateGearingEffect(
      historicalGrowthOverSelectedYears,
      property.financials.lVR
    );

    let gearingEffectPercent = '---';
    if (!isNaN(gearingEffect)) {
      gearingEffectPercent = gearingEffect::percentDecimal();
    }

    return (
      <FinancialsPanel
        title={
          property.propertyType === 'development'
            ? 'Investment Info - Development Phase'
            : 'Investment Info'
        }
        className="panel-financials-investment-yield panel-financials-table-icon"
      >
        <SectionTitle
          title="Rental Distribution Info"
          icon="coins"
          noPaddingTop
        />
        <div className="gray-line"></div>
        <RowContainer>
          <RowTitle title="Est. Net Rental Yield" />
          <RowValue
            className="net-rental-yield"
            value={
              <span>
                {property.propertyType === 'development'
                  ? '0%'
                  : property.financials.netRentalYield::percentDecimal()}{' '}
                <small style={{ fontSize: '18px' }}>p.a.</small>
              </span>
            }
            popoverText={netRentalIncomePopoverText}
          />
        </RowContainer>
        <div className="black-line"></div>
        <SectionTitle title="Capital Returns Info" icon="graph" />
        <div className="gray-line"></div>
        <RowContainer>
          <RowTitleWithSelector
            title={`${strToTitle(
              historicalGrowthTitle(property.propertyType)
            )}*`}
            options={yearsOptions.map((numberOfYears) => ({
              text: numberOfYears,
              value: numberOfYears,
            }))}
            selected={numberOfYearsSelected}
            onChange={(selected) =>
              this.setState({ numberOfYearsSelected: selected })
            }
            selectAboveTitle
            testRef="historical-growth-selector"
          />
          <RowValue
            className="historical-suburb-growth"
            value={
              <span>
                {averageAnnualGrowthPercent}{' '}
                <small style={{ fontSize: '18px' }}>p.a.</small>
              </span>
            }
            popoverText={historicalSuburbGrowthPopover}
          />
        </RowContainer>
        <div className="gray-line"></div>
        {ForecastCapitalGrowth[property.propertyCode] && (
          <Fragment>
            <RowContainer>
              <RowTitle
                title={
                  <span>
                    Development objective
                    <br />
                    capital growth rate
                  </span>
                }
              />
              <RowValue
                className="historical-suburb-growth"
                value={
                  <span>
                    {Formatters.numeric.percent(
                      ForecastCapitalGrowth[property.propertyCode]
                    )}{' '}
                    <small style={{ fontSize: '18px' }}>p.a.</small>
                  </span>
                }
                popoverText={developmentObjectiveCapitalGrowthRatePopoverText}
              />
            </RowContainer>
            <div className="gray-line"></div>
          </Fragment>
        )}

        <GearingRowContainer>
          <RowTitle
            title={`Gearing Effect (${property.financials.lVR::percent()} Debt)`}
          />
          <RowValue
            className="gearing-effect"
            value={gearingEffectPercent}
            popoverText={gearingEffectPopoverText}
          />
        </GearingRowContainer>
        <Spacing top="none" bottom="normal">
          <small className="asterisk-info">
            Learn more about how gearing works{' '}
            <Styledlink href={whatIsGearingEffectArticleUrl()} target="_blank">
              here
            </Styledlink>
            .
          </small>
        </Spacing>
        <div className="black-line"></div>
        <div className="row">
          <div className="col-xs-12">
            <small className="asterisk-info">
              *Past performance does not indicate future performance.
            </small>
            <small className="asterisk-info">
              Neither income nor capital returns are guaranteed.
            </small>
          </div>
        </div>
      </FinancialsPanel>
    );
  }
}

export default InvestmentYields;
